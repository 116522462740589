var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.idTable + "_container " } },
    [
      _c(
        "div",
        {
          staticClass: "table_scroll_duplicate",
          attrs: { id: _vm.idTable + "_scrollDuplicate" },
        },
        [_c("div", { attrs: { id: _vm.idTable + "_dummy" } })]
      ),
      _c("v-data-table", {
        attrs: {
          id: _vm.idTable,
          loading: _vm.loadingTable,
          headers: _vm.headers,
          options: _vm.pagination,
          items: _vm.items,
          page: _vm.pagination.page,
          "server-items-length": _vm.totalItems,
          "footer-props": {
            disablePagination: _vm.items.length === 0 || _vm.loadingTable,
            itemsPerPageOptions:
              _vm.totalItems < 50
                ? [
                    { value: 50, text: "50" },
                    { value: 100, text: "100", disabled: true },
                    { value: 250, text: "250", disabled: true },
                  ]
                : _vm.totalItems < 100
                ? [
                    { value: 50, text: "50" },
                    { value: 100, text: "100" },
                    { value: 250, text: "250", disabled: true },
                  ]
                : [
                    { value: 50, text: "50" },
                    { value: 100, text: "100" },
                    { value: 250, text: "250" },
                  ],
            disableItemsPerPage: _vm.items.length === 0 || _vm.loadingTable,
            itemsPerPageText: `${_vm.tableTitle} por página: `,
            expandIcon: "mdi-dots-horizontal",
          },
          "no-data-text": `Não há registros de ${_vm.tableTitle} a serem exibidos.`,
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
          "current-items": _vm.onTableInput,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "footer.page-text",
              fn: function (items) {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.loadingTable ? 0 : items.pageStart) +
                      " - " +
                      _vm._s(_vm.loadingTable ? 0 : items.pageStop) +
                      " de " +
                      _vm._s(_vm.loadingTable ? 0 : items.itemsLength) +
                      " " +
                      _vm._s(_vm.tableTitle) +
                      " "
                  ),
                ]
              },
            },
            _vm.loadingTable
              ? {
                  key: "body",
                  fn: function () {
                    return [
                      _c(
                        "tr",
                        _vm._l(_vm.headers, function (loadItem) {
                          return _c(
                            "td",
                            [
                              _c("v-skeleton-loader", {
                                staticClass: "loaderCss",
                                attrs: { "max-width": loadItem.width },
                              }),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            {
              key: "no-data",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-start",
                      attrs: { id: "noDataText" },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "Não há registros de " +
                            _vm._s(_vm.tableTitle) +
                            " a serem exibidos."
                        ),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "item.rubricNatureName",
              fn: function ({ item }) {
                return [
                  _c(
                    "span",
                    { staticClass: "col-max-min-width" },
                    [
                      _vm.checkLength(item.rubricNatureName, 38)
                        ? _c(
                            "AGTooltip",
                            {
                              attrs: { "tooltip-text": item.rubricNatureName },
                            },
                            [
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-no-wrap text-truncate" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.truncateText(
                                            item.rubricNatureName
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                            ],
                            2
                          )
                        : _c("div", [
                            _vm._v(" " + _vm._s(item.rubricNatureName) + " "),
                          ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "item.rubricNatureDesc",
              fn: function ({ item }) {
                return [
                  _c(
                    "span",
                    { staticClass: "col-max-min-width" },
                    [
                      _vm.checkLength(item.rubricNatureDesc, 48)
                        ? _c(
                            "AGTooltip",
                            {
                              attrs: { "tooltip-text": item.rubricNatureDesc },
                            },
                            [
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-no-wrap text-truncate" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.truncateText(
                                            item.rubricNatureDesc
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                            ],
                            2
                          )
                        : _c("div", [
                            _vm._v(" " + _vm._s(item.rubricNatureDesc) + " "),
                          ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "item.usesTetoRemun",
              fn: function ({ item }) {
                return [
                  item.usesTetoRemun
                    ? _c("span", [_vm._v(" Sim ")])
                    : _c("span", [_vm._v(" Não ")]),
                ]
              },
            },
            {
              key: "item.name",
              fn: function ({ item }) {
                return [
                  _vm.checkLength(item.name, 75)
                    ? _c(
                        "AGTooltip",
                        { attrs: { tooltipText: item.name } },
                        [
                          [
                            _c("span", { staticClass: "text-no-wrap" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.truncateText(item.name, 75)) +
                                  " "
                              ),
                            ]),
                          ],
                        ],
                        2
                      )
                    : _c("div", [
                        _c("span", { staticClass: "text-no-wrap" }, [
                          _vm._v(" " + _vm._s(item.name) + " "),
                        ]),
                      ]),
                ]
              },
            },
            {
              key: "item.actions",
              fn: function ({ item }) {
                return [
                  _c(
                    "AGTooltip",
                    {
                      staticStyle: { display: "inline-flex" },
                      attrs: { tooltipText: _vm.txt.edit.tooltip },
                    },
                    [
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "px-0 py-0",
                            attrs: { icon: "", id: "idEdit", width: "24px" },
                            on: {
                              click: function ($event) {
                                return _vm.openModalEdit(item)
                              },
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-0", attrs: { small: "" } },
                              [_vm._v(" mdi-pencil-outline")]
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                  _c(
                    "AGTooltip",
                    {
                      staticStyle: { display: "inline-flex" },
                      attrs: { tooltipText: _vm.txt.history.tooltip },
                    },
                    [
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "px-0 py-0",
                            attrs: {
                              icon: "",
                              id: "idHistoric",
                              width: "24px",
                              disabled: !_vm.isEnableHistory(item),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openModalHistoric(item)
                              },
                            },
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-0", attrs: { small: "" } },
                              [_vm._v(" mdi-history")]
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c("ModalIncidenceNatureHistory", {
        ref: "modalIncidenceNatureHistoric",
        attrs: { title: _vm.txt.history.title, history: _vm.arrHistoric },
      }),
      _c("ModalIncidenceNatureEdit", {
        ref: "modalIncidenceNatureEdit",
        attrs: { title: _vm.txt.edit.title, incidence: _vm.incidence },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }