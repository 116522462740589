<template>
  <div :id="idTable + '_container '">
    <div :id="idTable + '_scrollDuplicate'" class="table_scroll_duplicate">
      <div :id="idTable + '_dummy'"></div>
    </div>
    <v-data-table
      :id="idTable"
      :loading="loadingTable"
      :headers="headers"
      :options.sync="pagination"
      :items="items"
      :page="pagination.page"
      :server-items-length="totalItems"
      :footer-props="{
        disablePagination: items.length === 0 || loadingTable,
        itemsPerPageOptions:
          totalItems < 50
            ? [
                { value: 50, text: '50' },
                { value: 100, text: '100', disabled: true },
                { value: 250, text: '250', disabled: true },
              ]
            : totalItems < 100
            ? [
                { value: 50, text: '50' },
                { value: 100, text: '100' },
                { value: 250, text: '250', disabled: true },
              ]
            : [
                { value: 50, text: '50' },
                { value: 100, text: '100' },
                { value: 250, text: '250' },
              ],
        disableItemsPerPage: items.length === 0 || loadingTable,
        itemsPerPageText: `${tableTitle} por página: `,
        expandIcon: 'mdi-dots-horizontal',
      }"
      :no-data-text="`Não há registros de ${tableTitle} a serem exibidos.`"
      @current-items="onTableInput"
    >
      <template v-slot:footer.page-text="items">
        {{ loadingTable ? 0 : items.pageStart }} - {{ loadingTable ? 0 : items.pageStop }} de
        {{ loadingTable ? 0 : items.itemsLength }} {{ tableTitle }}
      </template>
      <template v-slot:body v-if="loadingTable">
        <tr>
          <td v-for="loadItem in headers">
            <v-skeleton-loader :max-width="loadItem.width" class="loaderCss"></v-skeleton-loader>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <div id="noDataText" class="d-flex justify-start">
          <p>Não há registros de {{ tableTitle }} a serem exibidos.</p>
        </div>
      </template>

      <template v-slot:item.rubricNatureName="{ item }">
        <span class="col-max-min-width">
          <AGTooltip v-if="checkLength(item.rubricNatureName, 38)" :tooltip-text="item.rubricNatureName">
            <template slot:tooltipArea>
              <div class="text-no-wrap text-truncate">
                {{ truncateText(item.rubricNatureName) }}
              </div>
            </template>
          </AGTooltip>
          <div v-else>
            {{ item.rubricNatureName }}
          </div>
        </span>
      </template>

      <template v-slot:item.rubricNatureDesc="{ item }">
        <span class="col-max-min-width">
          <AGTooltip v-if="checkLength(item.rubricNatureDesc, 48)" :tooltip-text="item.rubricNatureDesc">
            <template slot:tooltipArea>
              <div class="text-no-wrap text-truncate">
                {{ truncateText(item.rubricNatureDesc) }}
              </div>
            </template>
          </AGTooltip>
          <div v-else>
            {{ item.rubricNatureDesc }}
          </div>
        </span>
      </template>

      <template v-slot:item.usesTetoRemun="{ item }">
        <span v-if="item.usesTetoRemun"> Sim </span>
        <span v-else> Não </span>
      </template>

      <template v-slot:item.name="{ item }">
        <AGTooltip v-if="checkLength(item.name, 75)" :tooltipText="item.name">
          <template slot:tooltipArea>
            <span class="text-no-wrap">
              {{ truncateText(item.name, 75) }}
            </span>
          </template>
        </AGTooltip>
        <div v-else>
          <span class="text-no-wrap">
            {{ item.name }}
          </span>
        </div>
      </template>

      <template v-slot:item.actions="{ item }" class="acao">
        <AGTooltip :tooltipText="txt.edit.tooltip" style="display: inline-flex">
          <template slot:tooltipArea>
            <v-btn icon id="idEdit" width="24px" class="px-0 py-0" @click="openModalEdit(item)">
              <v-icon small class="mr-0"> mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
        </AGTooltip>
        <AGTooltip :tooltipText="txt.history.tooltip" style="display: inline-flex">
          <template slot:tooltipArea>
            <v-btn
              icon
              id="idHistoric"
              width="24px"
              class="px-0 py-0"
              :disabled="!isEnableHistory(item)"
              @click="openModalHistoric(item)"
            >
              <v-icon small class="mr-0"> mdi-history</v-icon>
            </v-btn>
          </template>
        </AGTooltip>
      </template>
    </v-data-table>
    <ModalIncidenceNatureHistory ref="modalIncidenceNatureHistoric" :title="txt.history.title" :history="arrHistoric" />
    <ModalIncidenceNatureEdit ref="modalIncidenceNatureEdit" :title="txt.edit.title" :incidence="incidence" />
  </div>
</template>

<script>
import { uniqueId } from 'lodash';
import { truncateText, checkLength } from '@/utils/text.js';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TableIncidenceNature',
  components: {
    AGTooltip: () => import('../../../commons/AGTooltip.vue'),
    ModalIncidenceNatureEdit: () => import('../components/ModalIncidenceNatureEdit.vue'),
    ModalIncidenceNatureHistory: () => import('../components/ModalIncidenceNatureHistory.vue'),
  },
  data() {
    return {
      incidence: {},
      txt: {
        history: {
          title: 'Histórico de atualização',
          tooltip: 'Histórico',
        },
        edit: {
          title: 'Editar incidência da natureza',
          tooltip: 'Editar',
        },
      },
    };
  },
  props: {
    idTable: {
      type: String,
      default: uniqueId('table_'),
    },

    loadingTable: {
      type: Boolean,
      default: false,
    },

    headers: {
      type: Array,
      required: true,
    },

    paginationFilter: {
      type: Object,
      default: () => {
        return {
          itemsPerPage: 50,
          page: 1,
          sortBy: [],
        };
      },
    },

    items: {
      type: Array,
      default: () => {
        return [];
      },
    },

    totalItems: {
      type: Number,
      required: false,
    },

    footerProps: {
      type: Object,
      default: () => {
        return {};
      },
    },

    tableTitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('cadastrosGerenciais/analysisParametersModule', {
      arrHistoric: 'getArrHistoric',
    }),
    pagination: {
      get: function () {
        return this.paginationFilter;
      },

      set: function (value) {
        this.$emit('setPagination', value);
      },
    },
  },
  mounted() {
    this.updateScrollers();
    this.setScrollersEventListener();
  },
  methods: {
    ...mapActions('cadastrosGerenciais/analysisParametersModule', ['fetchHistory']),
    truncateText,
    checkLength,

    isEnableHistory(item) {
      return (
        item.rubricTypeCode !== null &&
        item.inssIncidenceCode !== null &&
        item.irrfIncidenceCode !== null &&
        item.fgtsIncidenceCode !== null &&
        item.cprpIncidenceCode !== null &&
        item.usesTetoRemun !== null
      );
    },
    openModalEdit(item) {
      this.incidence = {};
      this.incidence = item;
      this.incidence.notes = '';
      setTimeout(() => {
        this.$refs.modalIncidenceNatureEdit.$emit('open');
      }, 500);
    },
    openModalHistoric(item) {
      this.fetchHistory(item.rubricNatureCode);
      setTimeout(() => {
        this.$refs.modalIncidenceNatureHistoric.$emit('open');
      }, 500);
    },
    setScrollersEventListener() {
      window.addEventListener('resize', this.updateScrollers);
      const linkScroller = (a, b) => {
        a.addEventListener('scroll', (e) => {
          b.scrollLeft = e.target.scrollLeft;
        });
      };
      const _a = this.$el.querySelector(`#${this.idTable}_scrollDuplicate`);
      const _b = this.$el.querySelector('.v-data-table__wrapper');
      linkScroller(_a, _b);
      linkScroller(_b, _a);
    },
    updateScrollers() {
      const dummy = this.$el.querySelector('#' + this.idTable + '_dummy');
      const _b = this.$el.querySelector('.v-data-table__wrapper');
      dummy.style.width = _b.scrollWidth + 'px';
    },
    onTableInput() {
      setTimeout(() => {
        this.updateScrollers();
      }, 250);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/design/components/table.scss';
@import './src/design/components/tooltip.scss';
.col-max-min-width {
  div {
    max-width: 318px;
  }
}
</style>
